import React, { useMemo, useRef, useState } from "react"
import FilterTable from "../components/FilterTable"
// import BasicTable from "../components/BasicTable"
//import PaginationTable from "../components/PaginationTable"
import { useLocation, useNavigate } from "react-router-dom"
import CustomDateRange from "../components/CustomDateRange"
import axios from 'axios'
import { onError } from "../lib/errorLib"
import xml2js from "xml2js"
import moment from "moment"
import { DateColumnFilter } from "../components/FilterTable"

export default function Transactions() {

    const { state } = useLocation()
    //console.log(state.transactions)
    var filterData = state.transactions
    //filterData = state.transactions.filter(item => item.CurrentStatus !== undefined)
    filterData.sort((a, b) => (a.OriginalIngestDatetime < b.OriginalIngestDatetime) ? 1 : ((b.OriginalIngestDatetime < a.OriginalIngestDatetime) ? -1 : 0))

    //Remove duplicates based on transaction and version
    filterData = filterData.filter((obj, index) => filterData.findIndex(
        (item) => item.TransactionNumber === obj.TransactionNumber && item.VersionNumber === obj.VersionNumber
    ) === index)

    const transactions = useRef([])
    const nav = useNavigate()
    const [status, setStatus] = useState('all')
    const [records, setRecords] = useState(filterData)
    // const [duration, setDuration] = useState(5)

    const zeroPad = (num, places) => String(num).padStart(places, '0')

    function convertDate(inputDate) {
        if (typeof inputDate !== 'undefined' && inputDate && inputDate != null) {
            let tempDate = inputDate.split('.')[0]
            let tempDate1 = tempDate.replace('T', ' ')
            return tempDate1
        }
        else {
            return ""
        }
    }

    function convertSource(inputSource) {
        if (typeof inputSource !== 'undefined' && inputSource && inputSource != null) {
            let tempSource = ""
            switch (inputSource.substr(0, 2)) {
                case '01':
                    tempSource = "AS400";
                    break;
                case '02':
                    tempSource = "RDB";
                    break;
                case '03':
                    tempSource = "CBSA";
                    break;
                case '04':
                    tempSource = "NISSAN";
                    break;
                default:
                    tempSource = "";
            }

            return tempSource
        }
        else {
            return ""
        }
    }

    const columns = useMemo(
        () => [
            {
                columns: [
                    {
                        Header: 'Transaction Number',
                        accessor: 'TransactionNumber',
                        Cell: item => <a href={zeroPad(item.value, 14)} onClick={(e) => handleDetails(e, zeroPad(item.value, 14), item)}> {zeroPad(item.value, 14)} </a>
                    },
                    {
                        Header: 'Importer BN15',
                        accessor: 'ImporterNumber',
                    },
                    {
                        Header: 'Submitted',
                        accessor: 'OriginalIngestDatetime',
                        Cell: ({ value }) => { return convertDate(value) },
                        Filter: DateColumnFilter,
                    },
                    {
                        Header: 'Version Number',
                        accessor: 'VersionNumber',
                    },
                    {
                        Header: 'Open',
                        accessor: 'CurrentStatus',
                    },
                    {
                        Header: 'Source',
                        accessor: 'EventId',
                        Cell: ({ value }) => { return convertSource(value) },
                    },
                    {
                        Header: 'Cad Accept Date',
                        accessor: 'CadAcceptDate',
                        Cell: ({ value }) => { return convertDate(value) },
                        Filter: DateColumnFilter,
                    },
                    {
                        Header: 'Type',
                        accessor: 'TypeCode'
                    },
                ],
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    const handleDetails = async (e, value, record) => {

        e.preventDefault()
        try {
            const output = await axios.get(process.env.REACT_APP_API_URL + '/transaction/A%23' + value)
            if (output.data.Count !== 0) {
                transactions.current = output.data.Items.filter(item => item.VersionNumber === record.row.original.VersionNumber)
            } else {
                onError("No History found for this transaction")
            }
        } catch (error) {
            console.log("Error occured while processing Server Request: ", error)
        }
        var inboundTransmissionNo;
        var outboundTransmissionNo;
        const responses = ["C#020701010507", "C#020701010510", "C#020701010506", "C#020701010513",
            "C#030701010507", "C#030701010510", "C#030701010506", "C#030701010513"]
        for (let i = 0; i < transactions.current.length; i++) {

            if (responses.includes(transactions.current[i].GSIPK01)) {
                inboundTransmissionNo = zeroPad(transactions.current[i].TransmissionNumber, 3)
            }
            if (transactions.current[i].GSIPK01 === "C#020501010511") {
                outboundTransmissionNo = zeroPad(transactions.current[i].TransmissionNumber, 3)
            }
        }

        //var importer = "N/A"
        var paymentDueDate = "N/A"
        var releaseDate = "N/A"
        var parser = ""

        if (inboundTransmissionNo) {
            const filename = 'Response_' + zeroPad(transactions.current[0].TransactionNumber, 14) + '_' + zeroPad(transactions.current[0].VersionNumber, 5) + '_' + inboundTransmissionNo + '.xml'
            const xmlString = await axios.get(process.env.REACT_APP_API_URL + '/retrieve/inboundFile/' + filename)
            if (xmlString.data) {
                parser = new xml2js.Parser()
                parser.parseString(xmlString.data, function (err, result) {
                    if (result['DocumentMetaData']['Response'][0]['Declaration'] !== undefined) {
                        if (result['DocumentMetaData']['Response'][0]['Declaration'][0]['DutyTaxFee'] !== undefined) {
                            var dutyTaxFee = result['DocumentMetaData']['Response'][0]['Declaration'][0]['DutyTaxFee']
                            for (var i = 0; i < dutyTaxFee.length; i++) {
                                if (dutyTaxFee[i]['TypeCode'][0] === "TOT") {
                                    if (dutyTaxFee[i]['Payment'][0]['DueDateTime'] !== undefined) {
                                        paymentDueDate = moment(dutyTaxFee[i]['Payment'][0]['DueDateTime'][0]['DateTimeString'][0], "YYYYMMDD")
                                        paymentDueDate = paymentDueDate.format("L")
                                    }
                                }
                            }
                            if (result['DocumentMetaData']['Response'][0]['Declaration'][0]['Status'][0]['ReleaseDateTime'] !== undefined) {
                                releaseDate = moment(result['DocumentMetaData']['Response'][0]['Declaration'][0]['Status'][0]['ReleaseDateTime'][0]['DateTimeString'][0], "YYYYMMDD")
                                releaseDate = releaseDate.format("L")
                            }
                        }
                    }
                })
            }
        }
        if (releaseDate === "N/A" && outboundTransmissionNo) {
            const filename = zeroPad(transactions.current[0].TransactionNumber, 14) + '_' + zeroPad(transactions.current[0].VersionNumber, 5) + '_' + outboundTransmissionNo + '.xml'
            const xmlString = await axios.get(process.env.REACT_APP_API_URL + '/retrieve/outboundFile/' + filename)
            if (xmlString.data) {
                parser = new xml2js.Parser()
                parser.parseString(xmlString.data, function (err, result) {
                    //importer = result['DocumentMetaData']['Declaration'][0]['Importer'][0]['ID'][0]
                    if (result['DocumentMetaData']['Declaration'][0]['Status'][0]['ReleaseDateTime'] !== undefined) {
                        releaseDate = moment(result['DocumentMetaData']['Declaration'][0]['Status'][0]['ReleaseDateTime'][0]['DateTimeString'][0], "YYYYMMDD")
                        releaseDate = releaseDate.format("L")
                    }
                })
            }
        }

        if (transactions.current) {
            let received = []
            let submitted = []
            var filterData = transactions.current.filter(item => (item.Notes !== "" && item.CurrentStatus === undefined))
            const responses = ["C#020701010507", "C#020701010510", "C#020701010506", "C#020701010513",
                "C#030701010507", "C#030701010510", "C#030701010506", "C#030701010513"]
            for (let i = 0; i < filterData.length; i++) {
                if (responses.includes(filterData[i].GSIPK01)) {
                    if (received.indexOf(zeroPad(filterData[i].TransmissionNumber, 3)) === -1) {
                        received.push(zeroPad(filterData[i].TransmissionNumber, 3))
                    }
                }
                if (filterData[i].GSIPK01 === "C#020501010511") {
                    submitted.push(zeroPad(filterData[i].TransmissionNumber, 3))
                }
            }

            nav("/transactiondetails", { state: { transactions: transactions.current, releaseDate: releaseDate, paymentDueDate: paymentDueDate, received: received, submitted: submitted } })
        }
    }

    return (
        <div>
            <h3>Transactions</h3>
            {state.search &&
                <nav className='filter-bar bg-white border-bottom'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col d-md-flex align-items-md-center px-0'>
                                <div className='date-range-wrapper border-bottom p-3 pr-md-0 pl-md-2 py-md-2'>
                                    <CustomDateRange />
                                </div>
                                {/* radio buttons */}
                                <div className='filter-radio-buttons d-flex'>
                                    <div className='custom-control custom-radio tag-radio-button m-1 flex-shrink-0'>
                                        <input type='radio' id='all' name='status' value='all' className='custom-control-input tag-input' checked={status === 'all'} onChange={() => {
                                            setStatus('all'); setRecords(filterData)
                                        }} />
                                        <label className='custom-control-label tag-label' htmlFor='all'>
                                            All
                                        </label>
                                    </div>
                                    <div className='custom-control custom-radio tag-radio-button m-1 flex-shrink-0'>
                                        <input type='radio' id='accepted' name='status' value='accepted' className='custom-control-input tag-input' checked={status === 'accepted'} onChange={() => { setStatus('accepted'); setRecords(filterData.filter(item => ((item.GSIPK01 === 'S#020701010507') || (item.GSIPK01 === 'S#030701010507')))) }} />
                                        <label className='custom-control-label tag-label' htmlFor='accepted'>
                                            Accepted
                                        </label>
                                    </div>
                                    <div className='custom-control custom-radio tag-radio-button m-1 flex-shrink-0'>
                                        <input type='radio' id='rejected' name='status' value='rejected' className='custom-control-input tag-input' checked={status === 'rejected'} onChange={() => { setStatus('rejected'); setRecords(filterData.filter(item => ((item.GSIPK01 === 'S#020701010510') || (item.GSIPK01 === 'S#030701010510')))) }} />
                                        <label className='custom-control-label tag-label' htmlFor='rejected'>
                                            Rejected
                                        </label>
                                    </div>
                                    <div className='custom-control custom-radio tag-radio-button m-1 flex-shrink-0'>
                                        <input type='radio' id='sent' name='status' value='sent' className='custom-control-input tag-input' checked={status === 'sent'} onChange={() => { setStatus('sent'); setRecords(filterData.filter(item => item.GSIPK01 === 'S#020501010511')) }} />
                                        <label className='custom-control-label tag-label' htmlFor='sent'>
                                            Sent
                                        </label>
                                    </div>
                                    <div className='custom-control custom-radio tag-radio-button m-1 flex-shrink-0'>
                                        <input type='radio' id='errors' name='status' value='errors' className='custom-control-input tag-input' checked={status === 'errors'} onChange={() => { setStatus('errors'); setRecords(filterData.filter(item => item.GSIPK01 === 'S#020101020799')) }} />
                                        <label className='custom-control-label tag-label' htmlFor='errors'>
                                            Errors
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='Manage-sub-button flex-shrink-0 ml-md-2'>
                                <button type='button' className='btn btn-primary' onClick={() => nav("/searchtransaction")}>
                                    Back to Search
                                </button>
                            </div>
                        </div>
                    </div>
                </nav>
            }
            {records.length > 0 &&
                <FilterTable columns={columns[0].columns} data={records} />
            }
            {records.length === 0 &&
                <h5>No Transaction Numbers for selected option</h5>
            }
            {/* <PaginationTable columns={columns[0].columns} data={state.transactions} /> */}
            {/* <BasicTable columns={columns[0].columns} data={data} /> */}
        </div>
    )
}
