import React from "react";
import { Navigate } from "react-router-dom";
import { useAppContext } from "../lib/contextLib";

export default function AuthenticatedRoute({ children, redirectTo }) {

    const { isAuthenticated } = useAppContext();
    return (
        isAuthenticated ? (
            children
        ) : (
            <Navigate to={redirectTo} />
        )
    );
}
