import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
// import Dashboard from "./containers/Dashboard";
import Api from "./containers/Api";
import SearchTransaction from "./containers/SearchTransaction";
import Transactions from "./containers/Transactions";
import Tracking from "./containers/Tracking";
import TransactionDetails from "./containers/TransactionDetails";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import CbsaTransaction from "./containers/CbsaTransaction";

export default function Links() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard" element={
                <AuthenticatedRoute redirectTo="/login">
                    <Tracking />
                </AuthenticatedRoute>
            }
            />
            <Route path="/apis" element={
                <AuthenticatedRoute redirectTo="/login">
                    <Api />
                </AuthenticatedRoute>
            }
            />
            <Route path="/searchtransaction" element={
                <AuthenticatedRoute redirectTo="/login">
                    <SearchTransaction />
                </AuthenticatedRoute>
            }
            />
            <Route path="/cbsatransaction" element={
                <AuthenticatedRoute redirectTo="/login">
                    <CbsaTransaction />
                </AuthenticatedRoute>
            }
            />
            <Route path="/transaction" element={
                <AuthenticatedRoute redirectTo="/login">
                    <Transactions />
                </AuthenticatedRoute>
            }
            />
            <Route path="/transactiondetails" element={
                <AuthenticatedRoute redirectTo="/login">
                    <TransactionDetails />
                </AuthenticatedRoute>
            }
            />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}