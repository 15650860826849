import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faHome,
    faCaretDown
} from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import { useState } from 'react';

function PortalSidebarNav({ sidebarSelected, showMenu, toggleMenu }) {
    const [selectedSection, setSelectedSection] = useState(null);

    const cadItems = [
        {
            title: 'Apis',
            path: '/apis'
        },
        {
            title: 'Transaction Search',
            path: '/searchtransaction'
        },
    ];

    const renderMenuEntry = (title, icon, to) => {
        const selectedClassName = selectedSection === `section-container-${title}` ? 'menu-item--active' : '';
        return (
            <NavLink
                className={`list-group-item sidebar-list-group-item d-flex align-items-center ${selectedClassName}`}
                to={to}
                onClick={(e) => {
                    sidebarSelected();
                    setSelectedSection(`section-container-${title}`);
                }}
            >
                <div className='d-flex justify-content-between align-items-center'>
                    <span className='d-flex align-items-center'>
                        <FontAwesomeIcon icon={icon} />
                        <div className='sidbar-nav-label text-decoration-none'>{title}</div>
                    </span>
                </div>
            </NavLink>
        );
    };

    const renderMenuWithSubItems = (name, title, items) => {
        const selectedClassName = selectedSection === `section-container-${name}` ? 'menu-item--active' : '';
        return (
            <div className='accordion sidebar-accordion' id={`${name}Accordion`}>
                <div className={`accordion-item sidebar-accordion-item d-flex flex-column section-container ${selectedClassName}`}>
                    <div
                        className='d-flex justify-content-between align-items-center sidebar-links toggle collapsed'
                        data-toggle='collapse'
                        data-target={`#${name}`}
                        aria-expanded='false'
                        aria-controls={`${name}`}
                        id={`${name}Header`}
                        // to={items[0].path}
                        onClick={(e) => {
                            setSelectedSection(`section-container-${name}`);
                        }}
                    >
                        <span className='d-flex align-items-center'>
                            <div className='sidbar-nav-label text-decoration-none'>{title}</div>
                        </span>

                        <span className='chevron-down'>
                            <FontAwesomeIcon icon={faCaretDown} />
                        </span>
                    </div>

                    {renderSubMenuList(name, items)}
                </div>
            </div>
        );
    };

    /**
     * Render sub items
     * @param {*} name
     * @param {*} items
     * @returns
     */
    const renderSubMenuList = (name, items) => (
        <div id={`${name}`} className='sidebar-nested-list collapse' aria-labelledby={`${name}Header`} data-parent='#site-sections'>
            <ul>
                {items.map((item, index) => (
                    < li key={`${name}Item-${index}`}>
                        <NavLink
                            to={item.path}
                            className={({ isActive }) => (isActive ? 'sub-item--active' : '')}
                            onClick={() => {
                                sidebarSelected();
                            }}
                        >
                            {item.title}
                        </NavLink>
                    </li>
                ))}
            </ul>
        </div >
    );

    return (
        <nav id='sidebar' className={`sidebar d-flex flex-column justify-content-between ${showMenu ? 'showing' : ''}`}>
            <div className='sidebar-menu'>
                <div id='site-sections' className='list-group sidebar-list-group'>
                    {/* GETTING STARTED */}
                    {renderMenuEntry('OOLA', faHome, '/dashboard')}
                    {renderMenuWithSubItems('CAD', 'CAD', cadItems)}
                </div>
            </div>
            <div className='sidebar-footer p-4 mb-2'>
                <h5 className='mb-2'>Need help?</h5>
            </div>
        </nav>
    );
}

export default PortalSidebarNav;