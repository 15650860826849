import React, { useMemo, useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faSearch } from '@fortawesome/free-solid-svg-icons'
import BasicTable from "../components/BasicTable"
//import filterGreaterThan from "../components/BasicTable"
import { useLocation } from "react-router-dom"
//import CustomDateRange from "../components/CustomDateRange"
import moment from "moment"

export default function TransactionDetails() {

    const [filters, setFilters] = useState([])
    const [search, setSearch] = useState([])
    //const [submitted, setSubmitted] = useState([])
    //const [received, setReceived] = useState([])
    const { state } = useLocation()
    const zeroPad = (num, places) => String(num).padStart(places, '0')
    //console.log(state.transactions)
    var filterData = state.transactions.filter(item => (item.Notes !== "" && item.CurrentStatus === undefined))
    filterData.sort((a, b) => (a.LastProcessedDatetime < b.LastProcessedDatetime) ? 1 : ((b.LastProcessedDatetime < a.LastProcessedDatetime) ? -1 : 0))

    var acceptDate = filterData[0].CadAcceptDate ? moment(new Date(filterData[0].CadAcceptDate)).format("L") : "N/A"

    function convertDate(inputDate) {
        if (typeof inputDate !== 'undefined' && inputDate && inputDate != null) {
            let tempDate = inputDate.split('.')[0]
            let tempDate1 = tempDate.replace('T', ' ')
            return tempDate1
        }
        else {
            return ""
        }
    }

    //const submittedReceived = async (e) => {
    //    for (let i = 0; i < filterData.length; i++) {
    //        if (filterData[i].GSIPK01 === "C#020701010507" || filterData[i].GSIPK01 === "C#020701010510") {
    //            setReceived(received => [...received, zeroPad(filterData[i].TransmissionNumber, 3)])
    //        }
    //        if (filterData[i].GSIPK01 === "C#020501010511") {
    //            setSubmitted(submitted => [...submitted, zeroPad(filterData[i].TransmissionNumber, 3)])
    //        }
    //    }
    //}

    async function fetchXml(e, filename, status) {

        try {
            if (status === "outbound") {
                window.open(process.env.REACT_APP_API_URL + '/retrieve/outboundFile/' + filename, '_blank')
            } else {
                window.open(process.env.REACT_APP_API_URL + '/retrieve/inboundFile/Response_' + filename, '_blank')
            }
        } catch (error) {
            console.log("Error occured while retriveing the xml: ", error)
        }
    }

    /* function handleDate(event) {

        var date = new Date()
        switch (event.target.textContent) {
            case 'Today':
                date.setDate(date.getDate() - 1);
                break;
            case '7 days':
                date.setDate(date.getDate() - 7);
                break;
            case '1 Month':
                date.setMonth(date.getMonth() - 1);
                break;
            case '3 Months':
                date.setMonth(date.getMonth() - 3);
                break;
            case '6 Months':
                date.setMonth(date.getMonth() - 6);
                break;
            case '1 Year':
                date.setFullYear(date.getFullYear() - 1);
                break;
            default:
                date.setMonth(date.getMonth() - 6);
        }
        var duration = new Date(date.toString().split('GMT')[0] + ' UTC').toISOString();
        var durationData = filterData.filter(n => n.LastProcessedDatetime >= duration);
        filterData = durationData;
    } */

    const columns = useMemo(
        () => [
            {
                columns: [
                    {
                        Header: 'Date',
                        accessor: 'LastProcessedDatetime',
                        Cell: ({ value }) => { return convertDate(value).substr(0, 10) },
                        // filter: filterGreaterThan,
                    },
                    {
                        Header: 'Time',
                        Cell: (props) => { return convertDate(props.row.original.LastProcessedDatetime).substr(11, 8) }
                    },
                    {
                        Header: 'Description',
                        accessor: 'Notes',
                    },
                ],
            },
        ],
        []
    )

    return (
        <div>
            <div className='header-with-icon-examples'>
                <div className='d-flex mb-2'>
                    <h2 className='mb-1'>Transaction {zeroPad(filterData[0].TransactionNumber, 14)}</h2>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-8 my-2 my-md-3">
                    <div className="card h-100">
                        <div className="order-sm-1 mr-0 mr-sm-4">
                            <h3 className="card-title">Details</h3>
                            <div className="card-body">
                                <dl className="row">
                                    <dd className="col-md-4 col-lg-3">Importer BN15:</dd>
                                    <dd className="col-md-4 col-lg-3">{filterData[0].ImporterNumber}</dd>
                                </dl>
                                <dl className="row">
                                    <dd className="col-md-4 col-lg-3">Version No.:</dd>
                                    <dd className="col-md-4 col-lg-3">{filterData[0].VersionNumber}</dd>
                                </dl>
                                <dl className="row">
                                    <dd className="col-md-4 col-lg-3">Payment Due Date:</dd>
                                    <dd className="col-md-4 col-lg-3">{state.paymentDueDate}</dd>
                                </dl>
                                <dl className="row">
                                    <dd className="col-md-4 col-lg-3">Release Date:</dd>
                                    <dd className="col-md-4 col-lg-3">{state.releaseDate}</dd>
                                </dl>
                                <dl className="row">
                                    <dd className="col-md-4 col-lg-3">Cad Accept Date:</dd>
                                    <dd className="col-md-4 col-lg-3">{acceptDate}</dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>

                {/* CARD 2 */}
                <div className="col-12 col-md-4 my-2 my-md-3">
                    <div className="card h-100">
                        <div className="order-sm-1 mr-0 mr-sm-4">
                            <h3 className="card-title">Status</h3>
                            <div className="card collapsable">
                                <div
                                    className="card-header"
                                    data-toggle="collapse"
                                    href="#collapseExample"
                                    role="button"
                                    aria-expanded="false"
                                    aria-controls="collapseExample"
                                >
                                    <h5>Submitted</h5>
                                    <FontAwesomeIcon icon={faChevronDown} />
                                </div>

                                <div className="collapse" id="collapseExample">
                                    <div className="card-body">
                                        <dl className="row">
                                            {state.submitted.map((option) => {
                                                return (
                                                    <dd className="col-md-4 col-lg-3"><button onClick={(e) => fetchXml(e, zeroPad(filterData[0].TransactionNumber, 14) + '_' + zeroPad(filterData[0].VersionNumber, 5) + '_' + zeroPad(option, 3) + '.xml', "outbound")}>{option}</button></dd>)
                                            })}
                                        </dl>
                                    </div>
                                </div>
                            </div>
                            <div className="card collapsable">
                                <div
                                    className="card-header"
                                    data-toggle="collapse"
                                    href="#collapseExample"
                                    role="button"
                                    aria-expanded="false"
                                    aria-controls="collapseExample"
                                >
                                    <h5>Received</h5>
                                    <FontAwesomeIcon icon={faChevronDown} />
                                </div>

                                <div className="collapse" id="collapseExample">
                                    <div className="card-body">
                                        <dl className="row">
                                            {state.received.map((option) => {
                                                return (
                                                    <dd className="col-md-4 col-lg-3"><button onClick={(e) => fetchXml(e, zeroPad(filterData[0].TransactionNumber, 14) + '_' + zeroPad(filterData[0].VersionNumber, 5) + '_' + zeroPad(option, 3) + '.xml', "inbound")}>{option}</button></dd>)
                                            })}
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* CARD 3 */}
                <div className="col-12 my-2 my-md-3">
                    <div className="card h-100">
                        <div className="row">
                            <div className="col-md-5">
                                <h3 className="card-title">History</h3>
                            </div>
                            {/* <div className='date-range-wrapper border-bottom p-3 pr-md-0 pl-md-3 py-md-0' id="date_range" onClick={(e) => { handleDate(e) }} >
                                <CustomDateRange />
                            </div> */}
                            <div className="col-md-5">
                                <div className='search-bar'>
                                    <div className='input-group form-group input-group-md align-items-center position-relative'>
                                        <input
                                            type='text'
                                            className='main-desktop-input form-control search-input'
                                            placeholder='Search'
                                            id="search_text"
                                            onBlur={(e) => { const nFilters = filters.filter(i => i.id !== 'Notes'); setFilters([...nFilters, { id: 'Notes', value: (e.target.value).trim() }]) }}
                                        />
                                        <div className='magnifying-glass-input-icon'>
                                            <FontAwesomeIcon icon={faSearch} />
                                        </div>
                                        <div className='input-group-append'>
                                            <button className='btn btn-primary' type='button' onClick={(e) => { setSearch(filters) }}>
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <BasicTable columns={columns[0].columns} data={filterData} searchText={search} />
                    </div>
                </div>
            </div>
        </div>
    )
}