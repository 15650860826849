import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import LoaderButton from "../components/LoaderButton";
import "./Login.css";
import axios from 'axios';
import { useAppContext } from "../lib/contextLib";
import { useNavigate } from "react-router-dom";
import { onError } from "../lib/errorLib";

export default function Login() {
    const { userHasAuthenticated } = useAppContext();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const nav = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    function validateForm() {
        return email.length > 0 && password.length > 0;
    }

    async function loginUser(credentials) {

        const response = await axios.post(process.env.REACT_APP_API_URL + '/login', JSON.stringify(credentials))
        return response.data
        // return fetch(process.env.REACT_APP_API_URL +'/login', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify(credentials)
        // }).then(data => data.json())
    }

    async function getUserRole(username) {

        const data = await axios.get(process.env.REACT_APP_API_URL + '/roles/' + username)
        const userRoles = data.data.Items;
        return userRoles;

    }

    async function handleSubmit(event) {
        event.preventDefault();
        setIsLoading(true);

        try {

            const token = await loginUser({ email, password });
            const roles = await getUserRole(email);

            if (roles.length > 0 && token != null) {
                userHasAuthenticated(true);
                nav("/dashboard");
            }

        } catch (e) {
            onError("Enter a valid user name/password");
            userHasAuthenticated(false);
            setIsLoading(false);
        }
    }

    return (
        <div className="Login">
            <Form onSubmit={handleSubmit}>
                <Form.Group size="lg" controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        autoFocus
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Form.Group>
                <Form.Group size="lg" controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Form.Group>
                <br></br>
                <LoaderButton
                    block="true"
                    size="lg"
                    type="submit"
                    isLoading={isLoading}
                    disabled={!validateForm()}
                >
                    Login
                </LoaderButton>
            </Form>
        </div>
    );
}