import React, { useState, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { useFormFields } from "../hooks/useFormFields"
import axios from 'axios'
import { onError } from "../lib/errorLib"

export default function CbsaTransaction() {

    const [fields, handleFieldChange] = useFormFields({
        transactionNumber: "",
        versionNumber: "",
    })

    const transactions = useRef([])
    const nav = useNavigate()

    const [cadGetApi, setCadGetApi] = useState(false)
    const cadGetStatus = useRef()

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms))
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (fields.transactionNumber && fields.versionNumber) {
            try {
                var output = await axios.get(process.env.REACT_APP_API_URL + '/transaction/A%23' + fields.transactionNumber)
                transactions.current = output.data.Items.filter(item => item.VersionNumber === parseInt(fields.versionNumber))
                if (transactions.current.length < 1 && !cadGetApi) {
                    onError("No Transactions found with search criteria")
                }
                if (transactions.current.length < 1 && cadGetApi) {//Not Existing in OOLA DB - Query CAD
                    alert("Querying CBSA....")
                    var obj = Object.create(null)
                    var lambda_fn_url = ""
                    const zeroPad = (num, places) => String(num).padStart(places, '0')
                    obj.transactionId = fields.transactionNumber + zeroPad(fields.versionNumber, 5) + '001'
                    obj.user = "Rez"
                    obj.source = "UI"
                    lambda_fn_url = process.env.REACT_APP_CAD_GET_API

                    var jsonString = JSON.stringify(obj)

                    await axios.post(lambda_fn_url, jsonString).then((response) => {
                        cadGetStatus.current = response.data
                    }, (error) => {
                        console.log(error) //Write the log else where instead of the console
                    })
                }
            } catch (error) {
                console.log("Error occured while processing Server Request: ", error)
            }
        }
        else {
            onError("Either Transaction or Version Number is missing")
        }
        if (transactions.current.length > 0) {
            nav("/transaction", { state: { transactions: transactions.current, search: true } })
        }
        if (cadGetApi) {
            if (cadGetStatus.current[1] === 200) {
                alert("Loading....")
                await sleep(5000)
                output = await axios.get(process.env.REACT_APP_API_URL + '/transaction/A%23' + fields.transactionNumber)
                transactions.current = output.data.Items.filter(item => item.VersionNumber === parseInt(fields.versionNumber))
                if (transactions.current.length < 1) {
                    onError("No Transactions were added as part of the pull from CBSA")
                } else {
                    nav("/transaction", { state: { transactions: transactions.current, search: true } })
                }
            } else {
                onError("Error response as part of the pull from CBSA")
            }
        }
    }
    return (
        <div>
            <div className='titleItem'>
                CBSA Transactions
            </div>

            <form>
                <div className='form-row'>
                    <label className="col-md-3 mb-3">
                        Transaction Number
                    </label>
                    <label className="col-md-3 mb-3">
                        Version Number
                    </label>
                </div>
                <div className='form-row'>
                    <div className="col-md-3" >
                        <input className="form-control" type="text" id="transactionNumber" placeholder="Transaction number" onChange={(e) => { handleFieldChange(e) }} />
                    </div>
                    <div className="col-md-3" >
                        <input className="form-control" type="text" id="versionNumber" placeholder="Version number" onChange={(e) => { handleFieldChange(e) }} />
                    </div>
                    <div className='custom-control custom-checkbox'>
                        <input type='checkbox' className='custom-control-input' id='customCheck1' onClick={() => setCadGetApi(!cadGetApi)} />
                        <label className='custom-control-label' htmlFor='customCheck1'>
                            Query CBSA
                        </label>
                    </div>
                    <div className='col-auto my-1'>
                        <button type="submit" className="btn btn-primary" onClick={(e) => handleSubmit(e)}>Submit</button>
                    </div>
                </div>
            </form>
        </div>
    )
}
