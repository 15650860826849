import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';

function Calendar(props) {
    const [currentMonth, setCurrentMonth] = useState(null);

    /**
     * Prevent closing the calendar
     * @param {*} e
     */
    const stopPropogation = (e) => {
        e.stopPropagation();
    };

    const getCurrentMonth = useCallback(
        (date) => {
            moment.locale(props.locale);
            let momentDate = moment(date);
            const month = momentDate.format('MMMM');
            const year = momentDate.format('YYYY');

            return { monthName: month, yearName: year, year: date.getFullYear(), month: date.getMonth(), weeks: getDaysInMonth(date) };
        },
        [props.locale]
    );

    useEffect(() => {
        const d = new Date(props.selectedDate);
        setCurrentMonth(getCurrentMonth(d && !isNaN(d) ? d : new Date()));
    }, [getCurrentMonth, props.selectedDate]);

    const getDaysInMonth = (d) => {
        const month = d.getMonth();
        let date = new Date(d.getFullYear(), month, 1);
        let daysInWeeks = [];
        let weekIndex = 0;

        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        firstDay.setDate(firstDay.getDate() - firstDay.getDay());

        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        lastDay.setDate(lastDay.getDate() - lastDay.getDay() + 6);

        let dayIndex;

        while (firstDay <= lastDay) {
            dayIndex = firstDay.getDay();

            if (!daysInWeeks[weekIndex]) daysInWeeks[weekIndex] = [];

            daysInWeeks[weekIndex][dayIndex] = new Date(firstDay.getTime());

            firstDay.setDate(firstDay.getDate() + 1);

            weekIndex = dayIndex === 6 ? weekIndex + 1 : weekIndex;
        }

        return daysInWeeks;
    };

    const moveMonth = (amount) => {
        let date = new Date(currentMonth.year, currentMonth.month, 1);
        date.setMonth(date.getMonth() + amount);
        setCurrentMonth(getCurrentMonth(date));
    };

    const selectMonth = (e) => {
        const selectedMonth = e.target.value;
        moveMonth(selectedMonth - currentMonth.month);
    };

    const selectYear = (e) => {
        const year = e.target.value;
        const date = new Date(year, currentMonth.month, 1);
        setCurrentMonth(getCurrentMonth(date));
    };

    const weekDays = () => {
        let date = new Date();
        date.setDate(date.getDate() - date.getDay());
        moment.locale(props.locale);
        let momentDate = moment(date);

        return [0, 1, 2, 3, 4, 5, 6].map((i) => {
            const result = (
                <th key={i} className='text-center day-name'>
                    {momentDate.format('ddd')}
                </th>
            );
            date.setDate(date.getDate() + 1);
            momentDate = moment(date);
            return result;
        });
    };

    const onSelect = (date) => {
        let dateString = '';
        if (props.customFormat) {
            dateString = props.customFormat(date);
        } else {
            dateString = date.toISOString();
        }

        setCurrentMonth(getCurrentMonth(date));

        props.onDateChanged(dateString, props.name);

        if (props.customAction) {
            props.customAction();
        }
    };

    const clearDate = () => {
        setCurrentMonth(getCurrentMonth(new Date()));

        props.onDateChanged('', props.name);

        if (props.customAction) {
            props.customAction();
        }
    };

    if (currentMonth === null) {
        return null;
    }

    let today = props.today ? new Date(props.today.getTime()) : new Date().setHours(0, 0, 0, 0);
    let startDate = props.startDate ? new Date(props.startDate.getTime()) : null;
    let endDate = props.endDate ? new Date(props.endDate.getTime()) : null;
    if (startDate) startDate.setHours(0, 0, 0, 0);
    if (endDate) endDate.setHours(0, 0, 0, 0);

    let weeks = currentMonth.weeks.map((w, weekIndex) => {
        let days = w.map((d, dayIndex) => {
            let className = 'day-btn btn btn-link btn-sm';
            let clickHandler = () => onSelect(d);

            if ((startDate && d < startDate) || (endDate && d > endDate)) {
                className = 'btn btn-sm disabled';
                clickHandler = null;
            } else if (d.getMonth() !== currentMonth.month) {
                className = 'btn btn-sm day-btn not-selectable';
            }

            if (d.getTime() === new Date(props.selectedDate + ' 00:00:00').getTime()) {
                className = 'day-btn btn btn-primary btn-sm';
            }

            return (
                <td key={'day-' + dayIndex}>
                    <span className={className} onClick={clickHandler}>
                        {d.getDate()}
                    </span>
                </td>
            );
        });

        return <tr key={'week-' + weekIndex}>{days}</tr>;
    });

    let monthNames = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((x) => {
        const dateOption = new Date(currentMonth.year, x, 1);
        return (
            <option key={x} value={x}>
                {moment(dateOption).format('MMMM')}
            </option>
        );
    });

    let yearRange = [];
    const currentYear = currentMonth.year;
    for (let i = currentYear - 50; i <= currentYear + 50; i++) {
        yearRange.push(i);
    }

    let years = yearRange.map((x) => {
        return (
            <option key={x} value={x}>
                {x}
            </option>
        );
    });

    const todayInRange = (!startDate || startDate < today) && (!endDate || endDate > today);

    const todayComponent = todayInRange ? (
        <span className='clear-and-today' onClick={() => onSelect(new Date(today))}>
            Today
        </span>
    ) : null;

    return (
        <table className='calendar-popup'>
            <thead>
                <tr>
                    <th onClick={(event) => stopPropogation(event)}>
                        <span className='btn btn-link btn-sm mr-1 month-cycle-arrow' onClick={() => moveMonth(-1)}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </span>
                    </th>
                    <th colSpan='5'>
                        <select
                            className='custom-select custom-select-sm mr-1'
                            onClick={(event) => stopPropogation(event)}
                            onChange={(e) => selectMonth(e)}
                            value={currentMonth.month}
                        >
                            {monthNames}
                        </select>
                        <select
                            className='custom-select custom-select-sm'
                            onClick={(event) => stopPropogation(event)}
                            onChange={(e) => selectYear(e)}
                            value={currentMonth.year}
                        >
                            {years}
                        </select>
                    </th>
                    <th onClick={(event) => stopPropogation(event)}>
                        <span className='btn btn-link btn-sm ml-1 month-cycle-arrow' onClick={() => moveMonth(1)}>
                            <FontAwesomeIcon icon={faArrowRight} />
                        </span>
                    </th>
                </tr>
                <tr>{weekDays()}</tr>
            </thead>
            <tbody>{weeks}</tbody>
            <tfoot>
                <tr>
                    <td colSpan='7'>
                        <span className='clear-and-today' onClick={() => clearDate()}>
                            Clear
                        </span>
                        {todayComponent}
                    </td>
                </tr>
            </tfoot>
        </table>
    );
}

export default Calendar;
