/* eslint-disable no-unused-vars */
import React, { useState } from "react"
import { Form, Row, Col, Button } from 'react-bootstrap'
import { useFormFields } from "../hooks/useFormFields"
import axios from 'axios'
import DateComponent from "../components/DateComponent"

export default function Api() {

    const [tariff, setTariff] = useState(false)
    const [exchange, setExchange] = useState(false)
    //    const [individual, setIndividual] = useState(0)
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')

    const [fields, handleFieldChange] = useFormFields({
        tariffNumber: "",
        asOfDate: "",
        tariffOption: "",
        filter: "",
    })

    const apiOptions = [
        { id: '1', label: 'TARIFF' },
        { id: '2', label: 'EXCHANGE RATE' }
    ]

    const tariffOptions = [
        { id: '1', label: 'ToDateCustomDuties' },
        { id: '2', label: 'ToDateExciseDuties' },
        { id: '3', label: 'ToDateExciseTaxCodes' }
    ]

    function dateDiffInDays(a, b) {
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        // Discard the time and time-zone information.
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    }

    const handleSelect = (e) => {
        if (e.target.value === '1') {
            setTariff(true)
        }
        if (e.target.value === '2') {
            setExchange(true)
            setTariff(false)
        }
    }

    const handleSubmit = () => {
        var obj = Object.create(null)
        var lambda_fn_url = ""
        var difference = 0

        if (tariff) {
            obj.apiName = "trfUpdate"
            obj.asOfDate = null
            obj.attempt = 0
            obj.apiRqstNum = 0
            obj.expand = null
            obj.inlineCount = null
            obj.fileName = null
            obj.filter = null
            obj.language = "EN"
            obj.orderBy = null
            obj.select = null
            obj.search = null
            obj.tariffNumber = null
            obj.user = "Rez"
            obj.xmlResponse = "200"
            lambda_fn_url = process.env.REACT_APP_TRF_API
        }

        if (exchange) {
            obj.apiName = "ExchangeRate"
            obj.language = "EN"
            obj.user = "Rez"
            obj.source = "NA"
            lambda_fn_url = process.env.REACT_APP_EXCH_API

            if (fromDate && !toDate) {
                alert("endDate should be populated")
            }
            if (!fromDate && toDate) {
                alert("startDate should be populated")
            }
            if (fromDate && toDate) {
                const a = new Date(fromDate)
                const b = new Date(toDate)
                difference = dateDiffInDays(a, b)
                if (difference > 1) {
                    alert("No. of days between startDate and endDate should not be greater than 1")
                }
                obj.startDate = fromDate
                obj.endDate = toDate
            }
        }

        var jsonString = JSON.stringify(obj)
        if (obj.apiName === "trfUpdate" || difference < 2) {
            axios.post(lambda_fn_url, jsonString).then((response) => {
                console.log(response.status) //Write the log else where instead of the console
                alert("The request has been submitted successfully")
            }, (error) => {
                console.log(error) //Write the log else where instead of the console
                alert("The request failed")
            })
        }
    }

    return (
        <div className='containerGrid'>
            <Form id="apis">
                <Form.Group as={Row}>
                    <Col md={3}>
                        <Form.Label>Api Type</Form.Label>
                    </Col>
                    <Col md={3}>
                        <Form.Select onChange={handleSelect} id="apiOption">
                            <option>Select</option>
                            {apiOptions.map((option, i) => (
                                <option key={i} value={option.id}>{option.label}</option>
                            ))}
                        </Form.Select>
                    </Col>
                </Form.Group>
                <br></br>
                {exchange && !tariff &&
                    <Form.Group as={Row}>
                        <div className="col-md-3">
                            <DateComponent
                                placeholder='YYYY-MM-DD'
                                dateLabel='Start Date'
                                valMsg='Invalid date message'
                                noBorder
                                noHeader
                                value={fromDate}
                                onDateChanged={setFromDate}
                                medium
                            />
                        </div>
                        <div className="col-md-3">
                            <DateComponent
                                placeholder='YYYY-MM-DD'
                                dateLabel='End Date'
                                valMsg='Invalid date message'
                                noBorder
                                noHeader
                                value={toDate}
                                onDateChanged={setToDate}
                                medium
                            />
                        </div>
                    </Form.Group>
                }
                <br></br>
                {(tariff || exchange) &&
                    <Form.Group as={Row}>
                        <Col md={3}>
                            <Button onClick={handleSubmit}>Submit</Button>
                        </Col>
                    </Form.Group>
                }
            </Form>
        </div>
    )
}
