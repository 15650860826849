import { faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import Calendar from './Calendar';
import InputBase from './Inputbase';
import Modal from './Modal';

const DateFormat = 'YYYY-MM-DD';

/**
 * label
 * value
 * onDateChanged
 * name
 * customValidationFunction (optional)
 * valMsg
 * placeHolder
 * disabled
 * readonly
 * noBorder
 * tooltip
 * allowEnter
 * embed
 * noHeader
 * small / medium / large
 *
 * @param {*} props
 * @returns
 */
function DateComponent(props) {
    const inputElementRef = useRef(null);
    const formGroupElementRef = useRef(null);
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);

    const [inErrorState, setInErrorState] = useState(false);

    const closeCalendarModal = () => {
        setIsCalendarOpen(false);
    };

    const handleDateChanged = (newValue) => {
        if (validateFormat(newValue)) {
            props.onDateChanged(newValue);
        }
    };

    const createCalendarModal = () => {
        const DATEFORMAT = DateFormat;
        const linkText = 'Select date';
        const calendarModal = (
            <Modal isOpen={isCalendarOpen} onRequestClose={() => closeCalendarModal()} title={linkText}>
                <div className='text-center'>
                    <Calendar
                        customFormat={(x) => {
                            return moment(x).startOf('day').format(DATEFORMAT);
                        }}
                        customAction={() => closeCalendarModal()}
                        today={props.today}
                        selectedDate={props.value}
                        onDateChanged={handleDateChanged}
                    ></Calendar>
                </div>
            </Modal>
        );
        return calendarModal;
    };

    const handleChange = (value) => {
        props.onDateChanged(value);
    };

    /**
     * Validate the date
     */
    const validateFormat = (newValue) => {
        const DATEFORMAT = DateFormat;
        if (!moment(newValue, DATEFORMAT, true).isValid()) {
            props.onDateChanged('');
            if (!newValue) {
                setErrorMsg(props.valMsg);
                setInErrorState(true);
                formGroupElementRef.current.classList.add('was-validated');
                inputElementRef.current.setCustomValidity('invalid');
                return false;
            }
        }

        if (props.customValidationFunction) {
            const result = props.customValidationFunction(newValue);
            if (result !== true) {
                props.onDateChanged('');
                setErrorMsg(result);
                setInErrorState(true);
                formGroupElementRef.current.classList.add('was-validated');
                inputElementRef.current.setCustomValidity('invalid');
                return false;
            }
        }

        formGroupElementRef.current.classList.remove('was-validated');
        inputElementRef.current.setCustomValidity('');
        setErrorMsg(null);
        setInErrorState(false);
        return true;
    };

    const calcInputSize = () => {
        let sizeClass;

        if (props.small) {
            sizeClass = 'form-control-sm';
        } else if (props.medium) {
            sizeClass = '';
        } else if (props.large) {
            sizeClass = 'form-control-lg';
        } else {
            sizeClass = '';
        }

        return sizeClass;
    };

    const calcInputIconSize = () => {
        let iconSizeClass;

        if (props.small) {
            iconSizeClass = 'input-icon-sm';
        } else if (props.medium) {
            iconSizeClass = 'input-icon-md';
        } else if (props.large) {
            iconSizeClass = 'input-icon-lg';
        } else {
            iconSizeClass = 'input-icon-md';
        }

        return iconSizeClass;
    };

    const DATEFORMAT = DateFormat;
    let date = '';
    const icon = <FontAwesomeIcon icon={faCalendarDay} />;
    if (props.value) {
        if (props.value.length > 10) {
            date = moment(props.value).isValid() ? moment(props.value).format(DATEFORMAT) : props.value;
        } else {
            date = props.value;
        }
    }
    const labelClass = props.valMsg ? 'date_time-field-validation-error' : '';
    const calendar = createCalendarModal();

    return (
        <div className='needs-validation' ref={formGroupElementRef}>
            <InputBase {...props}>
                <form className='date'>
                    <div className='form-group'>
                        {props.dateLabel && (
                            <label htmlFor='dateInput' className={labelClass}>
                                {props.dateLabel}
                            </label>
                        )}

                        <input
                            id='dateInput'
                            ref={inputElementRef}
                            placeholder={props.placeholder}
                            type='text'
                            value={date}
                            className={`form-control ${calcInputSize()} dropdown-toggle`}
                            onChange={(event) => handleChange(event.target.value)}
                            onBlur={(event) => {
                                if (event.target.value) {
                                    validateFormat(event.target.value);
                                }
                            }}
                            data-toggle='dropdown'
                            aria-haspopup='true'
                            autoComplete='off'
                            // name={props.name}
                            disabled={props.disabled}
                        />
                        {!inErrorState && <div className={calcInputIconSize()}>{icon}</div>}
                        <div className='invalid-feedback order-1'>{errorMsg}</div>
                        <div className='dropdown-menu' aria-labelledby='dateInput' role='button'>
                            {calendar}
                        </div>
                    </div>
                </form>
            </InputBase>
        </div>
    );
}

export default DateComponent;
