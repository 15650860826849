import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import "../styles.css";
import Sent from './Sent';
import Accepted from './Accepted';
import Rejected from './Rejected';
import Errors from './Errors';

export default function Tracking() {

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: false
    }
  };

  const [data, setData] = useState([])
  const [option, setOption] = useState("2")
  const [optionGraph, setGraph] = useState("2")

  useEffect(() => {
    if (option) {
      fetchData(option)
    }
  }, [option])

  const fetchData = async (option) => {
    try {
      const output = await axios.get(process.env.REACT_APP_API_URL + '/dashboard/duration/' + option)
      //const output = await axios.get('https://4l6twjg3z4.execute-api.us-east-1.amazonaws.com/dev/api/transactions/' + option)
      //const output = await axios.get('https://02g5teqrhe.execute-api.us-east-1.amazonaws.com/qa/api/transactions/' + option)
      //const output = await axios.get('http://localhost:8083/api/transactions/' + option)
      let records = output.data.Items
      let uniqueRecords = []
      records.map(x => uniqueRecords.filter(i => i.TransactionNumber === x.TransactionNumber && new Date(i.LastProcessedDatetime).getMonth() === new Date(x.LastProcessedDatetime).getMonth()).length > 0 ? null : uniqueRecords.push(x))

      let cData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      uniqueRecords.forEach((entry) => {
        const monNumber = new Date(entry.LastProcessedDatetime).getMonth()
        cData[monNumber] = cData[monNumber] + 1
      })
      setData(cData)
    } catch (error) {
      console.log("Error occured while processing Server Request: ", error)
    }
  };

  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  async function handleChange(event) {
    event.preventDefault()
    setOption(event.target.value)
  }

  let datavalue = {
    labels,
    datasets: [
      {
        data: data,
        borderColor: 'green',
        backgroundColor: 'rgba(86, 3, 25, 0.5)',
      },
    ],
  };

  return (
    <div className='containerGrid'>
      <div className='titleItem'>
        <span>DashBoard</span>
        <span className='dropdownTime'>
          <select id="sent" onChange={(e) => setGraph(e.target.value)} value={optionGraph}>
            <option value="1">Today</option>
            <option value="2">7 Days</option>
            <option value="3">1 Month</option>
            <option value="4">3 Months</option>
            <option value="5">6 Months</option>
            <option value="6">1 Year</option>
          </select>
        </span>
      </div>
      <div className='legendItem'>
        <div className='containerFlex'>
          <div className='flexItem'><Sent option={optionGraph}/></div>
          <div className='flexItem'><Accepted option={optionGraph}/></div>
          <div className='flexItem'><Rejected option={optionGraph}/></div>
          <div className='flexItem'><Errors option={optionGraph}/></div>
        </div>
      </div>
      <div className='graphHeaderItem'>
        <span>Tracking Overview</span>
        <span className='itemRight'>
          <select id="tracking" onChange={handleChange} value={option}>
            <option value="1">Today</option>
            <option value="2">7 Days</option>
            <option value="3">1 Month</option>
            <option value="4">3 Months</option>
            <option value="5">6 Months</option>
            <option value="6">1 Year</option>
          </select>
        </span>
      </div>
      <div className='graphItem'>
        <div className='largeRatio'>
          <Line options={options} data={datavalue} />
        </div>
      </div>
    </div>
  )
}