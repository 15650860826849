import React, { useState, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { useFormFields } from "../hooks/useFormFields"
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesDown, faAnglesRight } from '@fortawesome/free-solid-svg-icons'
import DateComponent from "../components/DateComponent"
import { onError } from "../lib/errorLib"
// import Transactions from "./Transactions"
import LoaderButton from "../components/LoaderButton"

export default function SearchTransaction() {

    const [fields, handleFieldChange] = useFormFields({
        importer: "",
        transactionNumber: "",
        versionNumber: "",
        category: "",
        status: "",
        source: "",
        type: "",
    })

    const transactions = useRef([])
    const cadGetStatus = useRef()
    const nav = useNavigate()

    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [cadAcceptDate, setCadAcceptDate] = useState('');
    const [searchType, setSearchType] = useState("transaction");
    const [extend, setExtend] = useState(false);
    const [cadGetApi, setCadGetApi] = useState(false)
    const [cadNew, setCadNew] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    //Change the below values if anything changes in event info table
    const statusOptions = [
        { id: '020501010511', label: 'SENT' },
        { id: '020701010507', label: 'ACCEPTED' },
        { id: '020701010510', label: 'REJECTED' },
        { id: '020701010599', label: 'ERRORS' }
    ]

    const sourceOptions = [
        { id: '01', label: 'AS400' },
        { id: '02', label: 'RDB' },
        { id: '03', label: 'PORTAL' },
        { id: '04', label: 'NISSAN' },
    ]

    const typeOptions = [
        { id: '10', label: 'Warehouse In' },
        { id: '13', label: 'Re-Warehouse' },
        { id: '20', label: 'Ex-Warehouse for Consumption' },
        { id: '21', label: 'Ex-Warehouse for Export' },
        { id: '30', label: 'Transfer of Goods' },
        { id: 'AB', label: 'With Release' },
        { id: 'F', label: 'Courier Low Value Shipment' },
        { id: 'TT', label: 'Customs Self Assessment' },
    ]

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms))
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        if (searchType === "transaction" && !fields.transactionNumber) {
            onError("Transaction Number have to be entered")
            setIsLoading(false)
        }

        if (searchType === "transaction" && fields.transactionNumber && !fields.versionNumber && cadGetApi && !cadNew) {
            onError("version number have to be entered")
            setIsLoading(false)
        }

        if (searchType === "transaction" && fields.transactionNumber) {
            try {
                let output = await axios.get(process.env.REACT_APP_API_URL + '/transaction/A%23' + fields.transactionNumber)
                output.data.Items.sort((a, b) => (a.OriginalIngestDatetime < b.OriginalIngestDatetime) ? 1 : ((b.OriginalIngestDatetime < a.OriginalIngestDatetime) ? -1 : 0))
                if (fields.versionNumber) {
                    transactions.current = output.data.Items.filter(item => item.VersionNumber === parseInt(fields.versionNumber))
                }
                else {
                    transactions.current = output.data.Items
                }

                if (transactions.current.length < 1 && !cadGetApi && !cadNew) {
                    onError("No Transactions found with search criteria")
                    setIsLoading(false)
                }

                if (transactions.current.length < 1 && cadGetApi) {//Not Existing in OOLA DB - Query CAD

                    var obj = Object.create(null)
                    var lambda_fn_url = ""
                    const zeroPad = (num, places) => String(num).padStart(places, '0')
                    if (cadNew) {
                        fields.versionNumber = '0'
                    }
                    if (transactions.current.length > 0 && transactions.current[0].TransmissionNumber !== undefined && transactions.current[0].TransmissionNumber !== null) {
                        obj.transactionId = fields.transactionNumber + zeroPad(fields.versionNumber, 5) + zeroPad(transactions.current[0].TransmissionNumber, 3)
                    } else {
                        obj.transactionId = fields.transactionNumber + zeroPad(fields.versionNumber, 5) + '000'
                    }

                    obj.user = "Rez"
                    obj.source = "UI"
                    lambda_fn_url = process.env.REACT_APP_CAD_GET_API

                    var jsonString = JSON.stringify(obj)

                    await axios.post(lambda_fn_url, jsonString).then((response) => {
                        cadGetStatus.current = response.data
                    }, (error) => {
                        onError("Error Cad Get process did not trigger")
                        setIsLoading(false)
                    })
                }
            } catch (error) {
                onError("Error occured while processing Server Request: ", error)
                setIsLoading(false)
            }
        }

        if (searchType === "importer") {
            if (fields.importer) {
                try {
                    const output = await axios.get(process.env.REACT_APP_API_URL + '/importer/' + fields.importer)
                    if (output.data.Count !== 0) {
                        transactions.current = output.data.Items.filter(item => (item.CurrentStatus !== "" && item.CurrentStatus !== undefined))
                        transactions.current = transactions.current.filter(item => fields.status ? (item.EventId === fields.status) : (item.EventId !== ""))
                        transactions.current = transactions.current.filter(item => fields.source ? (item.EventId.substr(0, 2) === fields.source) : (item.EventId.substr(0, 2) !== ""))
                        transactions.current = transactions.current.filter(item => fields.type ? (item.TypeCode === fields.type) : (item.TypeCode !== null))
                        transactions.current = transactions.current.filter(item => cadAcceptDate ? (item.CadAcceptDate.substr(0, 10) === cadAcceptDate) : (item.CadAcceptDate !== null))
                    }
                    if (transactions.current.length < 1) {
                        onError("No Transactions found with search criteria")
                        setIsLoading(false)
                    }
                } catch (error) {
                    onError("Error occured while processing Server Request: ", error)
                    setIsLoading(false)
                }
            } else {
                onError("Importer have to be entered")
                setIsLoading(false)
            }
        }

        if (searchType === "daterange" && fromDate && toDate) {
            try {
                if (toDate < fromDate) {
                    onError("To Date cannot be prior to Date submitted")
                    setIsLoading(false)
                }
                const output = await axios.get(process.env.REACT_APP_API_URL + '/transaction/event', { params: { startDate: fromDate, endDate: toDate } })
                if (output.data.Count !== 0) {
                    transactions.current = output.data.Items.filter(item => (item.CurrentStatus !== "" && item.CurrentStatus !== undefined))
                    transactions.current = transactions.current.filter(item => fields.status ? (item.EventId === fields.status) : (item.EventId !== ""))
                    transactions.current = transactions.current.filter(item => fields.source ? (item.EventId.substr(0, 2) === fields.source) : (item.EventId.substr(0, 2) !== ""))
                    transactions.current = transactions.current.filter(item => fields.type ? (item.TypeCode === fields.type) : (item.TypeCode !== null))
                    transactions.current = transactions.current.filter(item => cadAcceptDate ? (item.CadAcceptDate.substr(0, 10) === cadAcceptDate) : (item.CadAcceptDate !== null))
                }
                if (transactions.current.length < 1) {
                    onError("No Transactions found with search criteria")
                    setIsLoading(false)
                }

            } catch (error) {
                onError("Error occured while processing Server Request: ", error)
                setIsLoading(false)
            }
        }

        if (transactions.current.length > 0) {
            nav("/transaction", { state: { transactions: transactions.current, search: true } })
        }

        if (cadGetStatus.current !== undefined && cadGetStatus.current.length > 0) {
            if (cadGetStatus.current[5] === 200 || cadGetStatus.current[5] === 400) {
                const output = await axios.get(process.env.REACT_APP_API_URL + '/transaction/A%23' + fields.transactionNumber)
                await sleep(5000)
                if (fields.versionNumber !== '0') {
                    transactions.current = output.data.Items.filter(item => item.VersionNumber === parseInt(fields.versionNumber))
                } else {
                    transactions.current = output.data.Items
                }
                if (transactions.current.length < 1) {
                    onError("No Transactions were added as part of the pull from CBSA")
                    setIsLoading(false)
                } else {
                    nav("/transaction", { state: { transactions: transactions.current, search: true } })
                }
            } else {
                onError("Error response as part of the pull from CBSA")
                setIsLoading(false)
            }
        }
    }

    return (
        <div>
            <div>
                <div className='titleItem'>
                    Search Transactions
                </div>

                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className='d-flex flex-wrap needs-validation was-validated mt-3'>
                        <div className='custom-control custom-radio m-3'>
                            <input type='radio' id='transaction' name='customRadioInline5' className='custom-control-input' required
                                checked={searchType === "transaction"}
                                onChange={() => { setSearchType("transaction"); setExtend(false) }}
                            />
                            <label className='custom-control-label' htmlFor='transaction'>
                                By Transaction
                            </label>
                        </div>
                        <div className='custom-control custom-radio m-3'>
                            <input type='radio' id='daterange' name='customRadioInline5' className='custom-control-input' required
                                checked={searchType === "daterange"}
                                onChange={() => { setSearchType("daterange"); setExtend(false) }}
                            />
                            <label className='custom-control-label' htmlFor='daterange'>
                                By Date Range
                            </label>
                        </div>
                        <div className='custom-control custom-radio m-3'>
                            <input type='radio' id='importer' name='customRadioInline5' className='custom-control-input' required
                                checked={searchType === "importer"}
                                onChange={() => { setSearchType("importer"); setExtend(false) }}
                            />
                            <label className='custom-control-label' htmlFor='importer'>
                                By Importer
                            </label>
                        </div>
                    </div>
                    {searchType === "transaction" && (
                        <div>
                            <div className='row'>
                                <label className="col-md-3 mt-3">
                                    Transaction Number
                                </label>
                                <label className="col-md-3 mt-3">
                                    Version Number
                                </label>
                            </div>
                            <div className='row'>
                                <div className="col-md-3" >
                                    <input className="form-control" type="text" id="transactionNumber" placeholder="Transaction number" onChange={(e) => { handleFieldChange(e) }} />
                                </div>
                                <div className="col-md-3" >
                                    <input className="form-control" type="text" id="versionNumber" placeholder="Version number" onChange={(e) => { handleFieldChange(e) }} />
                                </div>
                                <div className='custom-control custom-checkbox mt-1'>
                                    <input type='checkbox' className='custom-control-input' id='customCheck1' onClick={() => setCadGetApi(!cadGetApi)} />
                                    <label className='custom-control-label' htmlFor='customCheck1'>
                                        Query CBSA
                                    </label>
                                </div>
                                <div className='custom-control custom-checkbox mt-1'>
                                    <input type='checkbox' className='custom-control-input' id='customCheck2' onClick={() => setCadNew(!cadNew)} />
                                    <label className='custom-control-label' htmlFor='customCheck2'>
                                        Retrieve Most Recent
                                    </label>
                                </div>
                            </div>
                        </div>
                    )}
                    {searchType === "daterange" && (
                        <div>
                            <div className='row'>
                                <div className="col-md-3 mt-3">
                                    <DateComponent
                                        placeholder='YYYY-MM-DD'
                                        dateLabel='Date submitted'
                                        valMsg='Invalid date message'
                                        noBorder
                                        noHeader
                                        value={fromDate}
                                        onDateChanged={setFromDate}
                                        medium
                                    />
                                </div>
                                <div className="col-md-3 mt-3">
                                    <DateComponent
                                        placeholder='YYYY-MM-DD'
                                        dateLabel='To Date'
                                        valMsg='Invalid date message'
                                        noBorder
                                        noHeader
                                        value={toDate}
                                        onDateChanged={setToDate}
                                        medium
                                    />
                                </div>
                                {!extend && (
                                    <div className='col-auto mt-5'>
                                        <FontAwesomeIcon icon={faAnglesDown} onClick={() => setExtend(true)} />
                                    </div>
                                )}
                                {extend && (
                                    <div className='col-auto mt-5'>
                                        <FontAwesomeIcon icon={faAnglesRight} onClick={() => setExtend(false)} />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    {searchType === "importer" && (
                        <div>
                            <div className='row'>
                                <label className="col-md-3 mt-3">
                                    Importer BN15
                                </label>
                            </div>
                            <div className='row'>
                                <div className="col-md-3">
                                    <input className="form-control" type="text" id="importer" placeholder="NN-NNNNNNNXX" onChange={(e) => { handleFieldChange(e) }} />
                                </div>
                                {!extend && (
                                    <div className='col-auto my-1'>
                                        <FontAwesomeIcon icon={faAnglesDown} onClick={() => setExtend(true)} />
                                    </div>
                                )}
                                {extend && (
                                    <div className='col-auto my-1'>
                                        <FontAwesomeIcon icon={faAnglesRight} onClick={() => setExtend(false)} />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {extend && (
                        <div>
                            <div className='row'>
                                <div className="col-md-3 mt-3">
                                    <label> Status </label>
                                    <select className="form-control" id="status" onChange={(e) => { handleFieldChange(e) }}>
                                        <option value="">Select</option>
                                        {statusOptions.map((option, i) => {
                                            return (
                                                <option key={i} value={option.id}>{option.label}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="col-md-3 mt-3">
                                    <label> Source </label>
                                    <select className="form-control" id="source" onChange={(e) => { handleFieldChange(e) }}>
                                        <option value="">Select</option>
                                        {sourceOptions.map((option, i) => {
                                            return (
                                                <option key={i} value={option.id}>{option.label}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="col-md-3 mt-3">
                                    <label> Type </label>
                                    <select className="form-control" id="type" onChange={(e) => { handleFieldChange(e) }}>
                                        <option value="">Select</option>
                                        {typeOptions.map((option, i) => {
                                            return (
                                                <option key={i} value={option.id}>{option.id}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="col-md-3 mt-3">
                                    <DateComponent
                                        placeholder='YYYY-MM-DD'
                                        dateLabel='CAD Accept Date'
                                        valMsg='Invalid date message'
                                        noBorder
                                        noHeader
                                        value={cadAcceptDate}
                                        onDateChanged={setCadAcceptDate}
                                        medium
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    <div className='search-button d-flex align-items-center justify-content-center mt-5'>
                        <LoaderButton
                            block="true"
                            size="lg"
                            type="submit"
                            isLoading={isLoading}
                        >
                            Search
                        </LoaderButton>
                    </div>
                </form>
            </div>
        </div>
    )
}
