import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export default function SessionTimeOutWarning(props) {

    return (
        <div>
            <Modal
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Session Timeout</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    The current session is about to expire in{" "}
                    <span>{props.countdown}</span> seconds.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={props.onLogout}>
                        Log Off
                    </Button>
                    <Button variant="primary" onClick={props.onContinue}>Stay Logged In</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}