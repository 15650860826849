import { LinkContainer } from "react-router-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./App.css";
import React, { useState, useEffect } from "react";
import { AppContext } from "./lib/contextLib";
import { useNavigate } from "react-router-dom";
import { onError } from "./lib/errorLib";
import Links from "./Routes";
import * as ProjectConstants from './utils/projectConstants';
import ScrollToTop from './utils/scrollToTop';
import useWindowDimensions from './utils/useWindowDimensions';
import PortalSidebarNav from './components/PortalSidebarnav';
import Appbar from "./containers/Appbar";
import SessionTimeOut from './components/SessionTimeOut';

function App() {

  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const nav = useNavigate();

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      userHasAuthenticated(false);
    } catch (e) {
      onError(e);
    }
    setIsAuthenticating(false);
  }

  const handleClick = () => {
    userHasAuthenticated(false);
    nav("/login");
  }

  // async function handleLogout() {
  //   userHasAuthenticated(false);
  //   nav("/login");
  // }

  const [showMenu, setShowMenu] = useState(false);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (width >= ProjectConstants.WindowWidthDesktop) {
      setShowMenu(true);
    } else {
      setShowMenu(false);
    }
  }, [width]);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const sidebarSelected = () => {
    if (width < ProjectConstants.WindowWidthDesktop) {
      setShowMenu(false);
    }
  };

  return (
    !isAuthenticating && (
      <div>
        <div>
          {!isAuthenticated &&
            <Navbar>
              <Navbar.Collapse className="justify-content-end">
                <Nav activeKey={window.location.pathname}>
                  {
                    // isAuthenticated ? (
                    //   <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
                    // ) : (
                    <>
                      <LinkContainer to="/login">
                        <Nav.Link>Login</Nav.Link>
                      </LinkContainer>
                    </>
                    // )
                  }
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          }
        </div>
        <div className='App d-flex'>
          <ScrollToTop />
          <PortalSidebarNav showMenu={isAuthenticated ? showMenu : ""} toggleMenu={toggleMenu} sidebarSelected={sidebarSelected} />
          <div className='navbar-and-stage-wrapper d-flex flex-column p-0'>
            <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
              {isAuthenticated &&
                <Appbar toggleMenu={toggleMenu} />
              }
              <Links />
            </AppContext.Provider>
            {isAuthenticated &&
              <SessionTimeOut authenticated={isAuthenticated} logOut={handleClick} />
            }
          </div>
          <div className={showMenu ? 'overlay' : ''}> </div>
        </div>
      </div>
    )
  );
}

export default App;
