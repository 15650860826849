import React, { useState } from "react";
//import IdleTimer from "react-idle-timer";
import { IdleTimerProvider, useIdleTimerContext } from 'react-idle-timer';
import SessionTimeOutWarning from "./SessionTimeOutWarning";

let countdownInterval;
let timeout;

export default function SessionTimeOut(authenticated) {

    const [modalOpen, setModalOpen] = useState(false);

    const [isAuthenticated, userHasAuthenticated] = useState(authenticated);

    const [timeoutCountdown, setTimeoutCountdown] = useState(0);

    const idleTimer = useIdleTimerContext();

    const clearSessionTimeout = () => {
        clearTimeout(timeout);
    };

    const clearSessionInterval = () => {
        clearInterval(countdownInterval);
    };

    const handleLogout = async (isTimedOut = false) => {
        try {
            setModalOpen(false);
            clearSessionInterval();
            clearSessionTimeout();
            userHasAuthenticated(false);
            window.location.reload();//Refresh the page
        } catch (err) {
            console.error(err);
        }
    };

    const handleContinue = () => {
        setModalOpen(false);
        clearSessionInterval();
        clearSessionTimeout();
    };

    const onActive = () => {
        if (!modalOpen) {
            clearSessionInterval();
            clearSessionTimeout();
        }
    };

    const onIdle = () => {
        const delay = 1000 * 1; //milliseconds
        if (isAuthenticated && !modalOpen) {
            timeout = setTimeout(() => {
                let countDown = 30; //counter
                setModalOpen(true);
                setTimeoutCountdown(countDown);
                countdownInterval = setInterval(() => {
                    if (countDown > 0) {
                        setTimeoutCountdown(--countDown);
                    } else {
                        handleLogout(true);
                    }
                }, 1000); //milliseconds
            }, delay);
        }
    };

    return (isAuthenticated && (
        <>
            <IdleTimerProvider
                ref={idleTimer}
                onActive={onActive}
                onIdle={onIdle}
                debounce={250} //milliseconds
                timeout={1000 * 60 * 5} //minutes
            />
            <SessionTimeOutWarning show={modalOpen}
                onHide={() => setModalOpen(false)} countdown={timeoutCountdown}
                onContinue={handleContinue}
                onLogout={() => handleLogout(false)} />
        </>
    )
    );
}