import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faUserCircle, faCog, faSignOutAlt, faBell } from '@fortawesome/free-solid-svg-icons'
import { useAppContext } from "../lib/contextLib";

function Appbar() {

    const { userHasAuthenticated } = useAppContext();

    async function handleLogout() {
        userHasAuthenticated(false);
    }

    return (
        <nav className="navbar align-items-center navbar-light bg-white shadow-sm px-2">
            <button className="btn btn-ghost-dark"><FontAwesomeIcon icon={faBars} /></button>
            <div className="d-flex align-items-center justify-center">
                <div className="dropdown">
                    <button type="button" className="btn btn-tertiary dropdown-toggle" id="usernameDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">OOLA User</button>
                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="usernameDropdown">
                        <a className="dropdown-item lead-icon user-email" href="/#"><div className="icon"></div> <div className="label">oola@livingston.com</div></a>
                        <div className="dropdown-divider"></div>
                        <a className="dropdown-item lead-icon" href="/#"><div className="icon"><FontAwesomeIcon icon={faUserCircle} /></div> <div className="label">Profile</div></a>
                        <a className="dropdown-item lead-icon" href="/#"><div className="icon"><FontAwesomeIcon icon={faCog} /></div> <div className="label">Settings</div></a>
                        <a className="dropdown-item lead-icon" href="/login" onClick={handleLogout}><div className="icon"><FontAwesomeIcon icon={faSignOutAlt} /></div> <div className="label">Logout</div></a>
                    </div>
                </div>
                <button type="button" className="btn btn-tertiary ml-1"><FontAwesomeIcon icon={faBell} /></button>
            </div>
        </nav>
    );
}

export default Appbar;