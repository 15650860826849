import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

export default function Accepted({ option }) {

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: false
        }
    };

    const labels = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    let cData = [0, 0, 0, 0, 0, 0, 0]

    const [data, SetData] = useState([])
    const [count, SetCount] = useState('0')
    //    const [transaction, setTransaction] = useState(false)

    const transactions = useRef([])

    const nav = useNavigate()

    useEffect(() => {
        if (option) {
            fetchData(option)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [option])

    const fetchData = async (option) => {

        const CADXMLPROCESSED = ['020701010507', '030701010507']
        let records = []
        let count = 0
        for (let i in CADXMLPROCESSED) {
            try {
                const output = await axios.get(process.env.REACT_APP_API_URL + '/dashboard/status/C%23' + CADXMLPROCESSED[i], { params: { timeline: option } })
                records = records.concat(output.data.Items)
                count = count + output.data.Count
            } catch (error) {
                console.log("Error occured while processing Server Request: ", error)
            }
        }
        transactions.current = records
        SetCount(count)
        records.forEach((entry) => {
            const dayNumber = new Date(entry.LastProcessedDatetime).getDay()
            cData[dayNumber] = cData[dayNumber] + 1
        })
        SetData(cData)
    };

    const onLinkClick = (e) => {
        e.preventDefault()
        //setTransaction(true)
        nav("/transaction", { state: { transactions: transactions.current, search: false } })
    }

    let datavalue = {
        labels,
        datasets: [
            {
                data: data,
                backgroundColor: 'rgba(147, 250, 165, 0.5)',
            },
        ],
    };

    return (
        <>
            <div className='itemBold'>Accepted</div>
            <div className='itemBoldCenter'>
                <a href='/transaction' onClick={(e) => onLinkClick(e)}>{count}</a>
            </div>
            <div className='ratio'><Bar options={options} data={datavalue} /></div>
        </>
    )
}