import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

export default function Sent({ option }) {

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: false
        }
    };

    const labels = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    let cData = [0, 0, 0, 0, 0, 0, 0]

    const [data, SetData] = useState([])
    const [count, SetCount] = useState('0')

    const transactions = useRef([])

    const nav = useNavigate()

    useEffect(() => {
        if (option) {
            fetchData(option)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [option])

    const fetchData = async (option) => {

        const CADXMLPOSTSUCCESS = '020501010511'

        try {
            const output = await axios.get(process.env.REACT_APP_API_URL + '/dashboard/status/S%23' + CADXMLPOSTSUCCESS, { params: { timeline: option } })
            let records = output.data.Items
            transactions.current = output.data.Items
            SetCount(output.data.Count)
            records.forEach((entry) => {
                const dayNumber = new Date(entry.LastProcessedDatetime).getDay()
                cData[dayNumber] = cData[dayNumber] + 1
            })
            SetData(cData)
        } catch (error) {
            console.log("Error occured while processing Server Request: ", error)
        }
    };

    const onLinkClick = (e) => {
        e.preventDefault()
        nav("/transaction", { state: { transactions: transactions.current, search: false } })
    }

    let datavalue = {
        labels,
        datasets: [
            {
                data: data,
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    };

    return (
        <>
            <div className='itemBold'>Sent</div>
            <div className='itemBoldCenter'>
                <a href='/transaction' onClick={(e) => onLinkClick(e)}>{count}</a>
            </div>
            <div className='ratio'><Bar options={options} data={datavalue} /></div>
        </>
    )
}