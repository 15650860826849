import React, { useEffect } from "react";
import { useFilters, useTable } from 'react-table';

export default function BasicTable({ columns, data, searchText }) {

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setAllFilters,
    } = useTable({
        columns,
        data,
    },
        useFilters
    )

    useEffect(() => {
        if (data?.length > 0) {
            setAllFilters(searchText)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText, data])

    // function filterGreaterThan(rows, id, filterValue) {
    //     console.log(rows)
    //     return rows.filter(row => {
    //         const rowValue = row.values[id]
    //         return rowValue >= filterValue
    //     })
    // }

    return (
        <table className="table" {...getTableProps()}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}