/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

const Today = 0;
const Last7days = 1;
const Last1Month = 2;
const Last3Months = 3;
const Last6Months = 4;
const Last1Year = 5;

const dateRangeOptions = [
    { label: 'Today', value: Today },
    { label: '7 days', value: Last7days },
    { label: '1 Month', value: Last1Month },
    { label: '3 Months', value: Last3Months },
    { label: '6 Months', value: Last6Months },
    { label: '1 Year', value: Last1Year }
];

function CustomDateRange() {
    const [showDateRange, setShowDateRange] = useState(false);
    const [selectedFilterOption, setSelectedFilterOption] = useState(Last1Year);
    const [activeDateRange, setActiveDateRange] = useState('1 Year');

    const addDateRangeOption = (label, value) => {
        const activeClass = activeDateRange === label ? 'active' : '';

        return (
            <div
                className={`dropdown-item d-flex justify-content-between align-items-stretch ${activeClass}`}
                role='button'
                onClick={() => {
                    setSelectedFilterOption(value);
                    setActiveDateRange(label);
                }}
            >
                <div className='mr-3 d-flex align-items-center'>{label}</div>
            </div>
        );
    };

    const renderDateRangeOptions = () => {
        return (
            <div className='filter-options show'>
                {dateRangeOptions.map((dateRangeOption) => addDateRangeOption(dateRangeOption.label, dateRangeOption.value))}
            </div>
        );
    };

    const renderSelectedOption = () => {
        const option = dateRangeOptions.find((dateRangeOption) => dateRangeOption.value === selectedFilterOption);
        return option.label;
    };

    return (
        <div className='dropdown'>
            {/* Toggle dropdown */}
            <button
                className='btn btn-tertiary dropdown-toggle btn-block'
                type='button'
                id='FilterButton'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
            >
                {renderSelectedOption()}
            </button>

            {/* DROPDOWN */}
            <div id='DateRangePicker' className='dropdown-menu' aria-labelledby='Filter'>
                {!showDateRange && renderDateRangeOptions()}
            </div>
        </div>
    );
}
export default CustomDateRange;
